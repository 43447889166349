import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Media,
  Table,
  Badge,
} from "react-bootstrap";
import "./BecomeAContentCreator.css";
import { connect } from "react-redux";
import {
  deleteBankAccountContentCreatorFlowStart,
  getBankAccountStart,
  makeDefaultBankAccountStart,
  addBankAccountContentreatorFlowStart,
} from "../../store/actions/BankAccountAction";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import NoDataFound from "../NoDataFound/NoDataFound";
import BillingAccountLoader from "../Loader/BillingAccountLoader";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { translate, t } from "react-multi-lang";
import { Formik, Form, Field, ErrorMessage } from "formik";

const Step4 = (props) => {

  const validationSchema = Yup.object().shape({
    account_holder_name: Yup.string().required(t("account_holder_name_required"))
      .matches(/^[aA-zZ\s]+$/, t("only_alphabets_are_allowed_for_this_field")),
    account_number: Yup.number()
      .required(t("account_number_required"))
      .min(0, t("invalid_account_number")),
    bank_name: Yup.string()
      .required(t("bank_name_required"))
      .matches(/^[aA-zZ\s]+$/, t("only_alphabets_are_allowed_for_this_field")),
    bank_country: Yup.string()
      .required(t("bank_country_required"))
      .matches(/^[aA-zZ\s]+$/, t("only_alphabets_are_allowed_for_this_field")),
    iban_number: Yup.string().required(t("iban_number_required")),
    swift_code: Yup.string().required(t("swift_code_required")),
    acceptTerms: Yup.bool().oneOf(
      [true],
      t("accept_terms_conditions_is_required")
    ),
  });

  useEffect(() => {
    if (props.user.data.content_creator_step === 0) {
      const notificationMessage = getErrorNotificationMessage(
        t("upload_doc_message")
      );
      props.dispatch(createNotification(notificationMessage));
      props.jumpToStep(0);
    } else if (props.user.data.content_creator_step === 1) {
      const notificationMessage = getErrorNotificationMessage(
        t("doc_verification_pending_message")
      );
      props.dispatch(fetchUserDetailsStart());
      props.dispatch(createNotification(notificationMessage));
      props.jumpToStep(2);
    }
    props.dispatch(getBankAccountStart());
  }, []);

  const handleSubmit = (values) => {
    props.dispatch(addBankAccountContentreatorFlowStart(values));
  };

  useEffect(() => {
    if (
      props.addBankAccount.data.success &&
      props.user.data.content_creator_step === 2
    ) {
      props.dispatch(fetchUserDetailsStart());
    }
  }, [props.addBankAccount.data]);

  return (
    <>
      <div className="step-5-payout-method-sec">
        <div className="step-5-info">
          <h4>{t("payout_methods")}</h4>
          <p>
            {t("payout_methods_description")}
            {/* {t('there_are_two_different_payment_types_available_to_you_below')} */}
          </p>
        </div>
        {props.bankAccount.loading ? (
          <BillingAccountLoader />
        ) : props.bankAccount.data.billing_accounts.length > 0 ? (
          <Row>
            <Col sm={12} md={12}>
              <div className="trans-table">
                <Table borderedless responsive>
                  <thead>
                    <tr className="bg-white text-muted text-center">
                      <th>{t("account_owner")}</th>
                      <th>{t("bank_name")}</th>
                      <th>{t("account_number")}</th>
                      <th>{t("bank_country")}</th>
                      <th>{t("iban")}</th>
                      <th>{t("swift_bic_code")}</th>
                      <th>{t("is_default")}</th>
                      <th>{t("status")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(props.bankAccount.data).length > 0 &&
                      props.bankAccount.data.billing_accounts.map((accounts) => (
                        <tr key={accounts.user_billing_account_id}>
                          <td className="text-capitalize">
                            {accounts.account_holder_name}
                          </td>
                          <td className="text-capitalize">
                            {accounts.bank_name}
                          </td>
                          <td>
                            {accounts.account_number}
                          </td>
                          <td className="amount">
                            {accounts.bank_country}
                          </td>
                          <td className="text-capitalize">
                            {accounts.iban_number}
                          </td>
                          <td className="text-capitalize">
                            {accounts.swift_code}
                          </td>
                          {accounts.is_default === 1 ? (
                            <td>
                              <Badge className="confirmed-badge">
                                {t("yes")}
                              </Badge>
                            </td>
                          ) : (
                            <td>
                              <Badge className="unconfirmed-badge">
                                {t("no")}
                              </Badge>
                            </td>
                          )}
                          <td>
                            {accounts.is_default === 0 ? (
                              <Button
                                variant="success"
                                onClick={() =>
                                  props.dispatch(
                                    makeDefaultBankAccountStart({
                                      user_billing_account_id:
                                        accounts.user_billing_account_id,
                                    })
                                  )
                                }
                              >
                                {t("make_default")}
                              </Button>
                            ) : null}{" "}
                            <Button
                              variant="danger"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    t("delete_billing_acc_confirmation")
                                  )
                                ) {
                                  props.dispatch(
                                    deleteBankAccountContentCreatorFlowStart({
                                      user_billing_account_id:
                                        accounts.user_billing_account_id,
                                    })
                                  );
                                }
                              }}
                            >
                              {t("delete")}
                            </Button>{" "}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        ) : null}
        <div className="step-5-payment-method">
          {/* <h5>Bank Transfer</h5> */}

          <Row>
            <Col sm={12} md={12}>
              <Formik
                initialValues={{
                  account_holder_name: "",
                  bank_name: "",
                  account_number: "",
                  bank_country: "",
                  iban_number: "",
                  swift_code: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ touched, errors, isSubmitting, setFieldValue, values }) => (
                  <Form noValidate>
                    <Row>
                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("account_owner")}: (*)
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={t("account_owner")}
                            name="account_holder_name"
                            autoFocus={true}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="account_holder_name"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("bank_name")}: (*)
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={t("bank_name")}
                            name="bank_name"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="bank_name"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("account_number")}: (*)
                          </label>
                          <Field
                            className="form-control"
                            type="number"
                            placeholder={t("account_number")}
                            name="account_number"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="account_number"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("bank_country")}: (*)
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={t("bank_country")}
                            name="bank_country"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="bank_country"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("iban")}: (*)
                          </label>
                          <Field
                            className="form-control"
                            type="number"
                            placeholder={t("iban")}
                            name="iban_number"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="iban_number"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("swift_bic_code")}: (*)
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={t("swift_bic_code")}
                            name="swift_code"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="swift_code"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>

                      <Col md={12}>
                        <label className="form-label mt-3">
                          <Link target="_blank" to="/page/terms" className="terms-link">
                            {" "}
                            {t("billing_account_agree_terms")}{" "}
                          </Link>
                        </label>
                      </Col>
                    </Row>
                    <div className="edit-save">
                      <Button
                        className="btn gradient-btn gradientcolor addBank"
                        type="submit"
                        disabled={props.addBankAccount.buttonDisable}
                      >
                        {props.addBankAccount.loadingButtonContent !== null
                          ? props.addBankAccount.loadingButtonContent
                          : t("submit")}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>

            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.bankAccount,
  addBankAccount: state.bankAccount.addBankAccountInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(Step4));
