import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Container, Row, Col } from "react-bootstrap";
import "./AddBankIndex.css";
import { addBankAccountStart } from "../../../store/actions/BankAccountAction";
import { translate, t } from "react-multi-lang";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const AddBankIndex = (props) => {

  const history = useHistory();
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    account_holder_name: Yup.string().required(t("account_holder_name_required"))
    .matches(/^[aA-zZ\s]+$/, t("only_alphabets_are_allowed_for_this_field")),
    account_number: Yup.number()
      .required(t("account_number_required"))
      .min(0, t("invalid_account_number")),
    bank_name: Yup.string()
      .required(t("bank_name_required"))
      .matches(/^[aA-zZ\s]+$/, t("only_alphabets_are_allowed_for_this_field")),
    bank_country: Yup.string()
      .required(t("bank_country_required"))
      .matches(/^[aA-zZ\s]+$/, t("only_alphabets_are_allowed_for_this_field")),
    iban_number: Yup.string().required(t("iban_number_required")),
    swift_code: Yup.string().required(t("swift_code_required")),
    acceptTerms: Yup.bool().oneOf(
      [true],
      t("accept_terms_conditions_is_required")
    ),
  });

  const handleSubmit = (values) => {
    props.dispatch(addBankAccountStart(values));
  };

  useEffect(() => {
    if (!skipRender && !props.bankAccount.loading &&
      Object.keys(props.bankAccount.data).length > 0) {
      history.push("/billing-accounts");
    }
    setSkipRender(false);
  }, [props.bankAccount]);

  return (
    <div className="card-list-sec">
      <Container>
        <Link
          className="bookmarkes-list notify-title back-button head-title"
          onClick={() =>
            props.location.state && props.location.state.prevPath
              ? props.history.goBack()
              : props.history.push("/home")
            // props.history.goBack()
          }
        >
          <img
            src={window.location.origin + "/assets/images/icons/back.svg"}
            className="svg-clone"
          />
          {t("add_bank")}
        </Link>
        {/* <h4 className="head-title">{t("add_bank")}</h4> */}
        <Row>
          <Col sm={12} md={12}>
            <div className="add-bank-box">
              <Formik
                initialValues={{
                  account_holder_name: "",
                  bank_name: "",
                  account_number: "",
                  bank_country: "",
                  iban_number: "",
                  swift_code: "",
                  acceptTerms: false,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ touched, errors, isSubmitting, setFieldValue, values }) => (
                  <Form noValidate>
                    <Row>
                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("account_owner")}: (*)
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={t("account_owner")}
                            name="account_holder_name"
                            autoFocus={true}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="account_holder_name"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("bank_name")}: (*)
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={t("bank_name")}
                            name="bank_name"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="bank_name"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("account_number")}: (*)
                          </label>
                          <Field
                            className="form-control"
                            type="number"
                            placeholder={t("account_number")}
                            name="account_number"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="account_number"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("bank_country")}: (*)
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={t("bank_country")}
                            name="bank_country"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="bank_country"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("iban")}: (*)
                          </label>
                          <Field
                            className="form-control"
                            type="number"
                            placeholder={t("iban")}
                            name="iban_number"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="iban_number"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("swift_bic_code")}: (*)
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={t("swift_bic_code")}
                            name="swift_code"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="swift_code"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div class="check-terms custom-control custom-checkbox custom-control-inline">
                          <Field
                            type="checkbox"
                            id="acceptterms"
                            name="acceptTerms"
                            custom
                            required={true}
                            className="custom-control-input"
                          />
                          <label
                            title=""
                            for="acceptterms"
                            class="custom-control-label"
                          ></label>
                        </div>
                        <label className="form-label">
                          {t("i_agree_to")}
                          <Link
                            target="_blank"
                            to="/page/terms"
                            className="terms-link"
                          >
                            {" "}
                            {t("terms_conditions")}{" "}
                          </Link>
                        </label>
                        <ErrorMessage
                          component={"div"}
                          name="acceptTerms"
                          className="error-msg text-danger text-right"
                        />
                      </Col>
                      <Col md={6}>

                      </Col>
                    </Row>
                    <div className="edit-save">
                      <Button
                        className="btn gradient-btn gradientcolor addBank"
                        type="submit"
                        disabled={props.bankAccount.buttonDisable}
                      >
                        {props.bankAccount.loadingButtonContent !== null
                          ? props.bankAccount.loadingButtonContent
                          : t("submit")}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.addBankAccountInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(AddBankIndex));
